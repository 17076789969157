import {
  GatsbyImageSharpFluid_WithWebpFragment,
  MarkdownRemarkFrontmatter,
  PageData,
} from '@types';
import { adaptImage } from './image';

export const adaptPage = (
  data:
    | {
        frontmatter?:
          | (Pick<
              MarkdownRemarkFrontmatter,
              'id' | 'title' | 'name' | 'description' | 'path'
            > & {
              image?:
                | {
                    childImageSharp?:
                      | {
                          fluid?:
                            | GatsbyImageSharpFluid_WithWebpFragment
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            })
          | null
          | undefined;
      }
    | null
    | undefined,
): PageData | null => {
  if (
    data?.frontmatter?.id &&
    data?.frontmatter?.name &&
    data?.frontmatter?.path
  ) {
    return {
      id: data.frontmatter.id,
      title: data.frontmatter.title || undefined,
      name: data.frontmatter.name,
      path: data.frontmatter.path,
      description: data.frontmatter.description || undefined,
      image: adaptImage(data.frontmatter.image),
    };
  }
  return null;
};

export const adaptPages = (data: {
  edges: {
    node: {
      frontmatter?: Pick<MarkdownRemarkFrontmatter, 'title'> | null | undefined;
    };
  }[];
}): PageData[] => {
  return data.edges
    .map(e => adaptPage(e.node))
    .flatMap(e => (e !== null ? [e] : []));
};
