import { useStaticQuery, graphql } from 'gatsby';
import { SiteSiteMetadata } from '@types';

export const useSiteMetadata = (): SiteSiteMetadata => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            subtitle
            siteUrl
            defaultDescription
            author
            phone
            email
            address
            copyright
            copyrightLink
          }
        }
      }
    `,
  );
  return site.siteMetadata;
};
